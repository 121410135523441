import React, { useEffect, useState, useRef } from 'react'
import './style.css';
import axios from 'axios';
import Post from './Post';
import { useNavigate } from "react-router-dom";
import { SEO } from '../SEO';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts } from '../../../actions';
import Spinner from 'react-bootstrap/Spinner';
import axiosInstance from "../../../helpers/axios";
import InfiniteScroll from "react-infinite-scroll-component";


/**
* @author
* @function BlogPage
**/



const BikeBlogPage = (props) => {
    const post = useSelector(state => state.post);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    const [pageCount, setPageCount] = useState();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const hasFetchedData = useRef(false);

    // function clicked() {
    // const path = '/createblog';
    // navigate(path);
    // }
    function Cuserror() {
        const path = '/*';
        navigate(path);
    }

    function fetchPosts() {
        axiosInstance.get(`/api/blog/getPosts/bikes?page=${page}`).then(res => {
            console.log(res.data.message);
            if(res.status === 200){
                setItems([...items, ...res.data.message]);
                setTotal(res.data.totalCount);
                setPageCount(res.data.pageCount);
            }
        }).catch(error => {
            console.log(error);
            Cuserror();
        });
    }

    

    useEffect(() => {
        if (navigate.action === 'POP') {

        } else {
            if (!hasFetchedData.current) {
                fetchPosts()
                hasFetchedData.current = true;
            }
        }
    },[items]);

    function fetchMorePosts() {
        setPage(page+1);
        axiosInstance.get(`/api/blog/getPosts?page=${page+1}`).then(res => {
            console.log(res.data.message);
            if(res.status === 200){
                setItems([...items, ...res.data.message]);
                setTotal(res.data.totalCount);
            }
        }).catch(error => {
            console.log(error);
            Cuserror();
        });
        
    }

    function getNewPostList(current, data) {
        const movies = {}
        // [...current, ...data].forEach(item => {
        //   movies[item.id] = item
        // })
        return Object.values(movies)
      }
    

    const renderPosts = (postss) => {
        console.log(items.length);
        if (!postss) {
            return '';
        }
        let newPosts = [];

        for (let post of postss) {
            newPosts.push(
                <Post key={post.index} {...post} />
            )
        }
        //setPage(page + 1);
        return newPosts;
    }


    return (
        <div className="main-div">
            <SEO
                title='Blog'
                description='This is regarding blog'
            />
            <main className='blog-main'>
                <InfiniteScroll
                dataLength={items.length}
                next={fetchMorePosts}
                hasMore={page === pageCount ? false : true}
                //you can create a spinner component which will be
                //displayed when the Items are being loaded
                loader={items.length>=total?"":<Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>}
            endMessage={<p style={{justifyContent:'center'}}>No More Data to Load</p>}
                >
                    {
                        renderPosts(items)
                    }
                </InfiniteScroll>
            </main>
            {/* <div className="post-float">
                <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <Button variant="success">*Disclaimer</Button>
            </OverlayTrigger>
            </div> */}

        </div>


    )

}

export default BikeBlogPage;