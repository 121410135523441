import { postConstants } from "../actions/constant";

const initState = {
    posts: null,
    post: null,
    loading: false,
    error: null
};


export default (state = initState, action) => {
    console.log(action);
    switch (action.type) {
        case postConstants.GET_POST_REQUEST:
            state = {
                ...state,
                loading: true,
                error: null,
                post: null,
                posts: null
            }
            break;
        case postConstants.GET_POST_SUCCESS:
            state = {
                ...state,
                loading: false,
                error: null,
                posts: action.payload.posts,
                post: null
            }
            break;
        case postConstants.GET_POST_FAILURE:
            state = {
                ...state,
                loading: false,
                post: null,
                error: action.payload.error,
                posts: null
            }
            break;
        case postConstants.GET_POST_BY_SLUG_REQUEST:
            state = {
                ...state,
                loading: true,
                post: null,
                error: null,
                posts: null
            }
            break;
        case postConstants.GET_POST_BY_SLUG_SUCCESS:
            state = {
                ...state,
                loading: false,
                post: action.payload.post,
                error: null,
                posts: null
            }
            break;
        case postConstants.GET_POST_BY_SLUG_FAILURE:
            state = {
                ...state,
                loading: false,
                post: null,
                error: action.payload.error,
                posts: null
            }
            break;
        case postConstants.POST_BLOG_REQUEST:
            state = {
                ...state,
                loading: true,
                post: null,
                error: null,
                posts: null
            }
            break;
        case postConstants.POST_BLOG_SUCCESS:
            state = {
                ...state,
                loading: false,
                post: action.payload.posts,
                error: null,
                posts: null
            }
            break;
        case postConstants.POST_BLOG_FAILURE:
            state = {
                ...state,
                loading: false,
                error: action.payload.error,
                post: null,
                posts: null
            }
            break;

    }

    return state;
}