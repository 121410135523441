import React from 'react';

/**
* @author
* @function AboutUsPage
**/

export const AboutUsPage = (props) => {
  return (
    <div>
      <main>
        <h1>About TheEVmotors.in</h1>

        <p>Welcome to TheEVmotors.in, your ultimate destination for comprehensive electric vehicle (EV) reviews, news, and insights. At TheEVmotors.in, we are passionate about driving the future of sustainable transportation and helping you navigate the exciting world of electric mobility.</p>

        <h2>Our Mission:</h2>

        <p>At <a href='www.theEVmotors.in'>TheEvmotors.in</a>, our mission is to be your trusted source for everything related to electric vehicles. We aim to provide in-depth and unbiased reviews of the latest EV models, keeping you informed about the cutting-edge technologies, performance features, and environmental benefits that make electric vehicles a game-changer in the automotive industry.</p>

        <h2>What Sets Us Apart:</h2>
        <span><h5>Expertise:</h5></span><p>Our team comprises automotive enthusiasts, industry experts, and environmental advocates dedicated to staying ahead of the curve in the rapidly evolving EV landscape.</p>

        <span><h5>Comprehensive Reviews:</h5></span><p> We go beyond the surface to deliver comprehensive reviews that not only highlight the strengths of electric vehicles but also address potential considerations for prospective buyers</p>

        <span><h5>Latest Updates:</h5></span><p>Stay informed with the latest news, trends, and innovations in the electric vehicle sector. Our team keeps a close eye on industry developments to bring you timely and relevant content.</p>

        <h2>Why Electric Vehicles:</h2>
        <p>We believe in the transformative power of electric vehicles to shape a more sustainable and environmentally friendly future. Our platform is designed to empower consumers with the knowledge they need to make informed choices, contributing to the widespread adoption of clean and efficient transportation alternatives.</p>

        <h2>Join Us on the Journey:</h2>
        <p>Whether you're a seasoned EV enthusiast or just starting to explore the world of electric vehicles, TheEVmotors.in is your go-to resource. Join us on this exciting journey as we explore, analyze, and celebrate the advancements in electric mobility.</p>

        <h2>Connect With Us:</h2>
        <p>Have questions, suggestions, or want to share your own EV experiences? We love hearing from our community. Connect with us on social media, and let's drive the conversation forward!</p>

        <p>Thank you for choosing TheEVmotors.in as your trusted guide to the electric vehicle revolution.</p>
        
        <h2>Address:</h2>
        <address>
          L376,Obeye Verbe<br></br>
          Near Sufyan Dates and nuts<br></br>
          HSR Layout,Bangalore<br></br>
          560102<br></br>
          Email:<a href='mailto:info@theevmotors.in'>info@theevmotors.in</a>
          </address>
      </main>
    </div>
  )

}