import React, { useState } from 'react';
import Login from './Login';  // Adjust the import path
import SignOut from '../SignOut/SignOut';  // Adjust the import path

const LoginPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleSignOut = () => {
    setIsLoggedIn(false);
  };

  return (
    <div className="app">
      {isLoggedIn ? (
        <SignOut onSignOut={handleSignOut} />
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
};

export default LoginPage;

