import React from 'react';
import { Link } from "react-router-dom";

/**
* @author
* @function NotFound
**/

const NotFound = (props) => {
    return (
        <div>
            <h1>Oops! You seem to be lost.</h1>
            <Link to='/'>Home</Link><br/>
        </div>
    )

}

export default NotFound;