import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GeolocationComponent = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [location, setLocation] = useState({ country: '', state: '' });
  const [error, setError] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setLatitude(lat);
          setLongitude(lon);

          try {
            // Fetch location details from reverse geocoding service
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
            const { address } = response.data;
            console.log("address", address)
            setLocation({
              country: address.country || '',
              state: address.state || address.region || ''
            });
          } catch (err) {
            setError('Error fetching location details');
          }
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h1>Geolocation Example</h1>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <div>
          <p>Latitude: {latitude ? latitude : 'Fetching...'}</p>
          <p>Longitude: {longitude ? longitude : 'Fetching...'}</p>
          <p>Country: {location.country || 'Fetching...'}</p>
          <p>State: {location.state || 'Fetching...'}</p>
        </div>
      )}
    </div>
  );
};

export default GeolocationComponent;
