import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Typography, Box, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import '../style.css';
import { useNavigate } from 'react-router-dom';
import PopupForm from '../../LeadForm/LeadForm';


const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 100, // Set a minimum width for the tabs
  width: 150, // Set a fixed width for the tabs
  '&.Mui-selected': {
    color: 'green', // Set the color for the selected tab
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        overflowX: 'auto', // Enable horizontal scrolling
        whiteSpace: 'nowrap', // Prevent line breaks
        padding: '20px' // Optional: Add padding for spacing
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {/* {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )} */}
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}
const CustomTabs = styled(Tabs)(({ theme }) => ({
  boxShadow: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: 'green',
  },
}));

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}



function ListWithTabBar({ tabs }) {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [showPopup, setIsPopupOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null); // State to pass car details to PopupForm


  const handleOffers = (car) => {
    setSelectedCar(car); // Store selected car details
    setIsPopupOpen(true); // This will open the popup
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // This will close the popup
    setSelectedCar(null); // Clear the selected car details

  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function clickedModel(props) {
    const { carSlug, makeSlug, images, baseVariant } = props;
    const state = { carSlug, makeSlug, images, baseVariant };
    navigate(`/${makeSlug}/${carSlug}`, { state: { state } })
  }

  function doNothing() {
    console.log("do nothing")
  }

  if (!tabs || tabs.length === 0) {
    return <Typography>No tabs data available</Typography>;
  }
  return (
    <div>
      <AppBar position="static" boxShadow='none' >
        <CustomTabs value={value}
          style={{ backgroundColor: 'white' }}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant='scrollable'
          scrollButtons='auto'
          indicatorColor="primary"
          textColor="black"
        >
          {tabs.map((tab, index) => (
            <StyledTab sx={{
              minWidth: 100,
              width: 150,
              color: value === index ? 'black' : 'gray',
            }} key={index} label={tab.title} />
          ))}
        </CustomTabs>
      </AppBar>
      <div className='mainProducts'>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.content.map(car => (
              <div className="car-card" key={car.id}>
                <div className="car-card-inner">
                  <div className="product-container-img">
                    <img
                      className="car-image"
                      src={car.image}
                      alt={car.name}
                      onClick={() => car.baseVariant.length > 0 ? clickedModel(car) : doNothing()}
                    />
                  </div>
                  <div className="product-info">
                    <a className="car-name" onClick={() => car.baseVariant.length > 0 ? clickedModel(car) : doNothing()}>
                      {car.name}
                    </a>
                    {
                      car.baseVariant.length > 0 ? <button className="get-offers-button" onClick={() => handleOffers(car)}>
                        Get Offers
                      </button> : <div />
                    }

                  </div>
                </div>
              </div>
            ))}
          </TabPanel>
        ))}
      </div>
      {showPopup && selectedCar && (
        <PopupForm car={selectedCar} onClose={handleClosePopup} />
      )}
    </div>
  );
}

export default ListWithTabBar;
