import React from 'react';
import BlogCard from '../BlogCard/BlogCard';
import './BlogList.modules.css'; // Import the CSS module

const BlogList = ({ items, onBlogClick }) => (
    <div className='blogListVertical'>
        {items.map((blog, index) => (
            <div key={index} className="blog-card" onClick={() => onBlogClick(blog)}>
            <h4 className="blog-title">{blog.title}</h4>
            <p className="blog-description">{blog.summary}</p>
        </div>
        ))}
    </div>
);

export default BlogList;