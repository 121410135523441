// src/components/BlogCard.js
import React from 'react';
import './BlogCard.css';
import { useNavigate } from "react-router-dom";


const BlogCard = ({ blog }) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/news_reviews/${blog.postSlug}`)
  };

  return (
    <div className="blog-card" onClick={handleCardClick}>
      <img src={`${process.env.REACT_APP_IMAGE_S3_URL}${blog.cover}`} alt={blog.title} className="blog-image" />
      <div className="blog-content">
        <h2 className="blog-title">{blog.title}</h2>
        <p className="blog-summary">{blog.summary}</p>
      </div>
    </div>
  );
};

export default BlogCard;
