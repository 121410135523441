import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './productStyle.css';
import Slider from "react-slick";
import CatSlider from '../../catSlider';
import HomeSlider from '../Slider';
import CollapsibleList from './CollapsibleList';
import Menu from './Menu/Menu';
import menu_config from './Menu/menu_config';
import { fetchAllVariantModel, fetchParticularModel } from '../../../helpers/axios';
import ShimmerLoader from './Shimmer/ShimmerLoader';

/**
* @author
* @function ProductDetailsPage
**/

export const ProductDetailsPage = (props) => {
  const state = useLocation();
  const [data, setData] = useState('');
  const [details, setDetails] = useState({});
  const [images, setImages] = useState([]);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCar, setSelectedCar] = useState("");

  const sliderRef = React.useRef(null);

  const courses = [
    'https://stimg.cardekho.com/images/carexteriorimages/630x420/Hyundai/Creta/8667/1705465218824/rear-parking-sensors-top-view-156.jpg?tr=w-664',
    'https://rukminim2.flixcart.com/image/128/128/xif0q/mobile/w/o/j/-original-imagwu89hxyttkbq.jpeg?q=70&crop=false',
    'https://rukminim2.flixcart.com/image/128/128/xif0q/mobile/w/o/j/-original-imagwu89hxyttkbq.jpeg?q=70&crop=false',
    'https://rukminim2.flixcart.com/image/128/128/xif0q/mobile/w/o/j/-original-imagwu89hxyttkbq.jpeg?q=70&crop=false',
    'https://rukminim2.flixcart.com/image/128/128/xif0q/mobile/w/o/j/-original-imagwu89hxyttkbq.jpeg?q=70&crop=false',
    'https://rukminim2.flixcart.com/image/128/128/xif0q/mobile/w/o/j/-original-imagwu89hxyttkbq.jpeg?q=70&crop=false',
    'https://rukminim2.flixcart.com/image/128/128/xif0q/mobile/w/o/j/-original-imagwu89hxyttkbq.jpeg?q=70&crop=false',
  ];


  var table_data = [
    { name: "charging Time", gender: "46hrs" },
    { name: "Battery Capacity", gender: "35 kWh" },
    { name: "Max Power(bhp@rpm)", gender: "120.69bhp" },
    { name: "Max Torque (nm@rpm))", gender: "190Nm" },
    { name: "Seating Capacity", gender: "5" },
    { name: "Range", gender: "431km" }
  ];

  const carsData = [
    { name: 'Toyota Camry', price: '$24,000' },
    { name: 'Honda Accord', price: '$26,000' },
    { name: 'Ford Mustang', price: '$30,000' },
    { name: 'Chevrolet Malibu', price: '$22,000' },
    // Add more car data as needed
  ];

  var settings = {
    slideToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    infinite: false,
    initialSlide: 0,
  }

  async function fetchVariant(props) {
    try {
      console.log("state details", props);
      setDetails(props.baseVariant[0])
      setSelectedCar(props.baseVariant[0])
      setImages(props.images);
      const result = await fetchAllVariantModel(props.makeSlug, props.carSlug);
      setCars(result.message);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  var prop = state.state.state;
  console.log('pr', prop);

  useEffect(() => {
    //setData(state.state.state);
    window.scrollTo(0, 0);
    fetchVariant(prop);
    
    //   setTimeout(() => {
    //     setCars(carsData);
    //     setLoading(false);
    // }, 2000);
  }, [ prop ]);

  var settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    infinite: false,
    fade: false,
    arrows: true,
    adaptiveHeight: false,
    ref: sliderRef,
  }

  const handleThumbnailClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const handleCarSelect = (e) => {
    const selectedValue = JSON.parse(e.target.value);
    if (selectedCar) {
      handleCarClick(selectedValue); // Pass the entire car object
      //setSelectedCar(e.target.value);
    }
  };

  const handleCarClick = async (car) => {
    console.log("Details", car)
    setDetails(car)
    // try {
    //   setLoading(true);
    //   const result = await fetchParticularModel(car.makeSlug, car.carSlug); // Adjust API call as needed
    //   setDetails(result); // Update details with the selected car's data
    //   setImages(result.images); // Update images with the selected car's images
    //   setLoading(false);
    // } catch (error) {
    //   console.error('Error fetching car details:', error);
    //   setLoading(false);
    // }
  };

  return (

    <div className="container-fluid">
      <div className="slider-thumbnail-container">
        <div className="main-slider">
          <Slider {...settings} className="zoomSlider">
            {images.map((item, index) => (
              <div key={index} className="slide-item">
                <img src={item} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>

        <div className="thumbnail-container">
          {images.map((item, index) => (
            <div
              key={index}
              className="thumbnail-item"
              onClick={() => handleThumbnailClick(index)}
            >
              <img src={item} alt={`Thumbnail ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      <div className="car-details-container">
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="car-details">
              <h1 className="car-title">{data.carVariantId}</h1>

              <div className="price-section">
                <div className="price-item">
                  <span className="car-name">{details.name}</span>
                </div>
                <div className="price-item">
                  <span className="car-tag">{details.tag}</span>
                </div>
                <div className="price-item">
                  <span className="car-price">{`Rs ${details.price}*`}</span>
                </div>
              </div>

              <div className="price-note">
                <span>Ex. Average showroom price</span>
              </div>
            </div>

          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <section className="mt-3">
              <h2>Variants</h2>
              <div className="car-list-container">
                {loading ? (
                  <>
                    <ShimmerLoader />
                    <ShimmerLoader />
                    <ShimmerLoader />
                    <ShimmerLoader />
                  </>
                ) : (
                  <select onChange={(selectedCar) => handleCarSelect(selectedCar)} className="car-dropdown">
                    <option value="" disabled>Select a car variant</option>
                    {cars.map((car, index) => (
                      <option key={index} value={JSON.stringify(car)}>
                        {car.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </section>

            {Array.isArray(details.specification) && details.specification.length > 0 && (
              <section className="mt-3">
                <h2 className="hd">Specifications</h2>
                <Menu items={details.specification} />
              </section>
            )}

            {Array.isArray(details.featured) && details.featured.length > 0 && (
              <section className="mt-3">
                <h2 className="hd">Features</h2>
                <Menu items={details.featured} />
              </section>
            )}
          </div>
        </div>
      </div>
    </div>


  )

}

{/* <div className='d-flex align-items-center'>
                <span>{`${data.avgRating} (${data.ratingDesc})`}</span>
              </div> */}
{/* <div className='roomSlider'>
        <Slider {...settings} className="room_slider_main">
          <div className='item'>
          <img src='https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/399d6f9c9ec27f34.jpg?q=20' />
          </div>
        </Slider>
      </div> */}

{/* <CatSlider slide={1} url={images} /> */ }

{/* <img src={data.image} alt='img' /> */ }