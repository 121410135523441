import React from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate(); // Use navigate for button actions

  const handleAddModelClick = () => {
    navigate('/add/model');
  };

  const handleAddVariantClick = () => {
    navigate('/add/model/variant');
  };

  return (
    <div className="dashboard">
      <h2>Dashboard</h2>
      <div className="button-group">
        <button className="action-button" onClick={handleAddModelClick}>
          Add Model
        </button>
        <button className="action-button" onClick={handleAddVariantClick}>
          Add Variant
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
