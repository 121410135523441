import React from 'react';
import './BlogPage.css';

function BlogPage1() {
  return (
    <div className="blog-page">
      <header className="header">
        <h1>Your Blog Title</h1>
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#articles">Articles</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <div className="content-wrapper">
        <main className="main-content">
          <article>
            <h2>Article Title</h2>
            <p>Your blog content...</p>
          </article>
        </main>
        
        <aside className="sidebar">
          <div className="sidebar-item">
            <h3>Popular Posts</h3>
            <ul>
              <li><a href="#post1">Post 1</a></li>
              <li><a href="#post2">Post 2</a></li>
            </ul>
          </div>
        </aside>
      </div>

      <footer className="footer">
        <p>© 2024 Your Blog. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default BlogPage1;
