import { useEffect, useState } from "react";
import { fetchEvAllVariant, performAddSpecorFeature } from "../../helpers/axios";
import "./style.css";
import { handleAuthorizationError } from "../../helpers/errorutils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const FeatureOrSpec = ({ formData, setFormData }) => {
    const [ variants, setVariants ] = useState([]);
    
    const fetchVariants = async() => {
        const result = await fetchEvAllVariant();
        console.log('Result', result);
        setVariants(result);
    };
    
    useEffect(() => {
        fetchVariants();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [ name ]: value,
        }))
    }

    const handleNestedChange = (path, index, event) => {
        const { name, value, type, checked } = event.target;
        const updatedValue = type === 'checkbox' ? checked : value;
        
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, [name]: updatedValue };
    
            const pathParts = path.split('.');
            let currentLevel = updatedFormData;
    
            for (let i = 0; i < pathParts.length; i++) {
                const part = pathParts[i];
    
                if (i === pathParts.length - 1) {
                    // Initialize the array if it doesn't exist
                    if (!Array.isArray(currentLevel[part])) {
                        currentLevel[part] = [];
                    }

                    currentLevel[part][index] = {
                        ...currentLevel[part][index],
                        [name]: updatedValue,
                    };
                } else {
                    // Initialize objects or arrays along the path if they don't exist
                    if (!currentLevel[part]) {
                        currentLevel[part] = isNaN(pathParts[i + 1]) ? {} : [];
                    }

                    currentLevel = currentLevel[part];
                }
            }
    
            return updatedFormData;
        });
    };

    const addItem = () => {
        setFormData({
          ...formData,
          items: [
            ...formData.items,
            { text: '', value: '', icon: '', desc: '', available: false, isTestedValue: false },
          ],
        });
    };
    
    const removeItem = (index) => {
        const updatedItems = formData.items.filter((_, i) => i !== index);
        setFormData({ ...formData, items: updatedItems });
    };

    const navigate = useNavigate();

    const postFeature = async (formData) => {
        try {
          const result = await performAddSpecorFeature(formData);
          console.log(result.message);
        } catch (error) {
          console.error('Error fetching data:', error);
          try {
            handleAuthorizationError(error, navigate);
          } catch (e) {
            // Handle non-authorization errors here
            toast.error("An error occurred: " + (error.response?.data?.message || "Please try again later."));
          }
        }
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Submitted', formData);
        postFeature(formData);
    };

    const heading = formData.type === 'feature' ? 'Feature' : 'Specification';

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <h1>Add { heading }</h1>

                <div className="form-group">
                    <label>{ heading } Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={(e) => handleChange(e)}
                    />
                </div>

                <div className="form-group">
                    <label>Variant:</label>
                    <select
                        name="variantSlug"
                        value={formData.variantSlug}
                        onChange={handleChange}
                    >
                        <option value="">Select a variant</option>
                        {variants?.map((variant) => (
                            <option key={variant._id} value={variant.variantSlug}>
                                {variant.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <h4>Items</h4>

                    {formData.items.map((item, itemIndex) => (
                        <div key={itemIndex} className="nested-item">
                            <div className="form-group">
                                <label>Text:</label>
                                <input type="text" name='text' value={item.text} onChange={(e) => handleNestedChange(`items`, itemIndex, e)} />
                            </div>

                            <div className="form-group">
                                <label>Value:</label>
                                <input type="text" name='value' value={item.value} onChange={(e) => handleNestedChange(`items`, itemIndex, e)} />
                            </div>

                            <div className="form-group">
                                <label>Icon:</label>
                                <input type="text" name='icon' value={item.icon} onChange={(e) => handleNestedChange(`items`, itemIndex, e)} />
                            </div>

                            <div className="form-group">
                                <label>Description:</label>
                                <input type="text" name='description' value={item.desc} onChange={(e) => handleNestedChange(`items`, itemIndex, e)} />
                            </div>

                            <div className="form-group">
                                <label>Available:</label>
                                <input type="checkbox" name='available' checked={item.available} onChange={(e) => handleNestedChange(`items`, itemIndex, e)} />
                            </div>

                            <div className="form-group">
                                <label>Is Tested Value:</label>
                                <input type="checkbox" name='isTestedValue' checked={item.isTestedValue} onChange={(e) => handleNestedChange(`items`, itemIndex, e)} />
                            </div>

                            <button type="button" className="remove-item" onClick={() => removeItem(itemIndex)}>Remove Item</button>
                        </div>
                    ))}

                    <button type="button" className="add-item" onClick={() => addItem()}>Add Item</button>
              </div>

              <button type="submit">Submit</button>
            </form>
        </div>        
    )
}

export default FeatureOrSpec;