import React, { useEffect, useState } from 'react';
import HydrogenFAQ from '../HydrogenCars/Accordin/HydrogenFAQ';
import Badge from 'react-bootstrap/Badge';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import { SEO } from '../SEO';
import './hybridstyle.css';

/**
* @author
* @function HybridCarsPage
**/

export const HybridCarsPage = (props) => {

    // const imgUrl = 'https://martialvolvocars.in/wp-content/uploads/xc60-gallery-1-16x9-1.webp';
    const imgUrl = 'https://static3.toyotabharat.com/images/showroom/innova-hycross/kv-banner.jpg';
    const cars = [
        {
            name: "Toyota Innova Hycross",
            price: "24.85 - 38.71 Lakh",
            unit: '85BB65',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwE6o3QOfmdQ3Yw2g13JqZYV_zyabTvI-L0w&usqp=CAU',
            isNewLaunched: false,
            isExpected: false
        },
        {
            name: "Toyota Urban Cruiser Hyryder",
            price: "13.90 - 25.19 Lakh",
            unit: '85BB65',
            image: 'https://static3.toyotabharat.com/images/showroom/urbancruiser-hyryder/exterior/exterior-1.jpg',
            isNewLaunched: true,
            isExpected: false
        },

        {
            name: "Maruti Grand Vitara",
            price: "13.24 - 24.53 Lakh",
            unit: '85BB65',
            image: 'https://nexaprod4.azureedge.net/-/media/feature/nexawebsitecarbrand/grand-vitara/brand-color/688x360/nexa_blue.webp',
            isNewLaunched: true,
            isExpected: false
        },
        {
            name: "Toyota Camry",
            price: "57.61 Lakh",
            unit: '85BB65',
            image: 'https://static3.toyotabharat.com/images/showroom/camry/new/test-drive-desk.webp',
            isNewLaunched: false,
            isExpected: false

        },
        {
            name: "Mercedes-Benz GLE",
            price: "1.18 - 1.41 cr",
            unit: '85BB65',
            image: 'https://www.mercedes-benz.co.in/content/india/en/passengercars/models/suv/v167-fl-23-2/overview/_jcr_content/root/responsivegrid/media_slider/media_slider_item_co/image.component.damq6.3397335253123.png/Microsoft-image.png',
            isNewLaunched: true,
            isExpected: false

        },
        {
            name: "Lexus ES",
            price: "78.12 Lakh",
            unit: '85BB65',
            image: 'https://www.lexusindia.co.in/content/dam/lexus-v3-india/model-pages/es/my23/es-divider-1920x800.jpg',
            isNewLaunched: false,
            isExpected: false

        },
        {
            name: "BMW XM",
            price: "3.28 cr",
            unit: '85BB65',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnsdE24oJk0DKRqpe1xkbB6AvnDLrhaQZfSg&usqp=CAU',
            isNewLaunched: true,
            isExpected: false

        },
        {
            name: "Volve XC60",
            price: "84.55 Lakh",
            unit: '85BB65',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyQ0sJ3Bo7moeWg_MuK5f9nBUp7r5wkTWPmw&usqp=CAU',
            isNewLaunched: true,
            isExpected: false

        },
        {
            name: "Mercedes-Benz Maybach S-Class",
            price: "3.28 - 4.15 cr",
            unit: '85BB65',
            image: 'https://imgd-ct.aeplcdn.com/664x415/n/cw/ec/115149/maybach-s-class-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80',
            isNewLaunched: false,
            isExpected: false

        }
    ];

    const faqs = [
        {
            "id":"1",
            "ques":"What is the future of hybrid cars in India?",
            "ans":"The future of hybrid cars in India looks promising as the automotive industry undergoes a shift towards eco-friendly options. Hybrid vehicles, combining both electric and traditional fuel elements, offer a transitional solution to sustainable transportation. With advancements in hybrid technology and growing environmental consciousness, anticipate an increased presence of hybrid cars on Indian roads. The future holds potential for further innovation and integration of hybrid systems to enhance fuel efficiency and reduce emissions."
        },
        {
            "id":"2",
            "ques":"Why Are Hybrids More Expensive than Conventional Vehicles?",
            "ans":"There are two major reasons that hybrids are more expensive than conventional cars. One is that conventional cars have been around for longer than hybrids, which means that manufacturers more or less have the kinks ironed out. The risk of having to do a widespread recall is much lower on cars that use conventional and proven technologies."
        },{
            "id":"3",
            "ques":"How does a hybrid car work?",
            "ans":"Hybrid cars operate through a combination of an internal combustion engine and an electric motor. The system seamlessly switches between the two power sources or utilizes them simultaneously based on driving conditions. During low speeds or when idling, the electric motor is engaged, reducing fuel consumption and emissions. At higher speeds or when additional power is needed, the gasoline engine takes over. Through regenerative braking, energy is also reclaimed to recharge the hybrid battery. This dual-power approach enhances fuel efficiency and reduces environmental impact, making hybrid cars a more sustainable transportation option."
        },{
            "id":"4",
            "ques":"How does the battery of a hybrid vehicle charge?",
            "ans":" The battery of a hybrid vehicle charges through a process known as regenerative braking and, in some cases, by drawing power from the internal combustion engine. During regenerative braking, kinetic energy generated when slowing down is converted into electric energy, which is then used to recharge the hybrid battery. Additionally, the internal combustion engine can act as a generator to charge the battery when needed. The hybrid system intelligently manages the charging process, optimizing fuel efficiency and reducing reliance on external charging sources, distinguishing it from fully electric vehicles."
        },{
            "id":"5",
            "ques":"Can I drive a hybrid vehicle without using the electric motor?",
            "ans":"Yes, you can drive a hybrid vehicle without using the electric motor. Hybrid cars seamlessly switch between the internal combustion engine and the electric motor based on driving conditions. In some cases, such as highway driving or higher speeds, the vehicle may rely more on the gasoline engine. The flexibility of a hybrid system allows for a smooth and conventional driving experience."
        },{
            "id":"6",
            "ques":" Do hybrid vehicles require special maintenance?",
            "ans":"Hybrid vehicles generally don't require special maintenance beyond regular check-ups and servicing. The hybrid components are designed to function seamlessly with routine maintenance procedures. However, it's essential to follow the manufacturer's recommended maintenance schedule and have the vehicle serviced by qualified technicians familiar with hybrid systems to ensure optimal performance."
        }
    ];
    const [cars_list] = useState(cars);
    const [faq] = useState(faqs);
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
  return(
    <div className='hydrogen'>
        <SEO
      title='Hybrid Cars'
      description={faq[0].ans}
      />
    <section className='billboard' style={{backgroundImage: 'url(' + imgUrl + ')' }} >
        {/* <div className='home-text'>
            <h1>2023 Mirai</h1>
            <p style={{color:'#172554',background:'white'}}>Hydrogen car generates power by combining hydrogen with oxygen from the outside air.</p>
        </div> */}
    </section>

    <div style={{
                width:'95%',
                margin: '20px auto',
            }}>
    <div className='card'>
        <header className='cardHeader'>
            <h2>Popular Hybrid Cars</h2>
        </header>
        <div className='mainProducts'>
            {
                cars_list.map((data, index) =>
                    <div className='product' key={index}>
                        <div className='product-container'>
                            <div className='box'>
                                {data.isExpected === true ? <Badge className='badge' bg="warning">Expected in India</Badge> : ""}
                                <img src={data.image} alt='' />
                                <h2>{data.name}</h2>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </div>

    <section className='cardHeader'>
        <h2>FAQs?</h2>
        {
            faq.map(({id,ques,ans})=> (
                <HydrogenFAQ id={id} title={ques} content={ans} />
            ))
        }
    </section>
    </div>
    


</div>
   )

 }

