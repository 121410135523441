import React, { useEffect, useState } from 'react'
import data from '../../data/electricCar.json'
import { useLocation } from 'react-router-dom';
import '../NewCars/newcars.css'
import { NavLink, useNavigate } from 'react-router-dom';
import HydrogenFAQ from '../HydrogenCars/Accordin/HydrogenFAQ';
import { SEO } from '../SEO';
import Badge from 'react-bootstrap/Badge';
import { Tabs } from '../../../containers/TabPane/tabs';
import Tabpane from '../../../containers/TabPane/tabpane';
import { fetchEvMake, fetchEvModel, fetchEvModelBySlug } from '../../../helpers/axios';

/**
* @author
* @function NewCarPage
**/

export const NewCarPage = (props) => {



  const faqs = [
    {
      "id": "1",
      "ques": "What is the future of electric vehicles in India?",
      "ans": "The future of electric vehicles (EVs) in India is promising and poised for significant growth. With increasing environmental awareness, government incentives, and advancements in technology, the adoption of EVs is on the rise. Expect to see a surge in EV infrastructure development, improved battery technology, and a wider range of electric models. As India moves towards sustainable transportation, the future holds exciting prospects for the electric vehicle industry."
    },
    {
      "id": "2",
      "ques": "How can electric cars help us save the environment?",
      "ans": "Electric cars produce zero tailpipe emissions, and new upcoming EV cars in India will run on battery. So as the need for petrol or diesel becomes zero, air pollution and greenhouse gas emissions are reduced to a great extent."
    },
    {
      "id": "3",
      "ques": "What are the benefits of electric vehicles?",
      "ans": "The benefits of electric vehicles are not limited to lower operating costs. It extends to reduced environmental impact, less noise pollution, and a smooth driving experience thanks to instant torque."
    },
    {
      "id": "4",
      "ques": "What technology is used in electric cars?",
      "ans": "Electric cars operate using lithium-ion batteries for energy storage. Electric motors, regenerative braking systems, and advanced software for power distribution and efficiency are other premium-grade technologies used in upcoming electric cars in India."
    },
    {
      "id": "6",
      "ques": "How much time does it take to fully charge an EV ?",
      "ans": "The duration for a full charge varies depending on the brand and the vehicle's voltage capacity. DC fast charging typically takes around an hour, while using an AC socket may require approximately 6-7 hours for a complete charge."
    },
    {
      "id": "8",
      "ques": "Is it safe to charge an EV in rain?",
      "ans": "Charging your EV in the rain is generally safe, but ensure there are no issues with the charging point or cords to avoid any potential risks or complications."
    },
    {
      "id": "8",
      "ques": "Where can I purchase an electric vehicle?",
      "ans": " Explore the extensive range of electric vehicles available on Theevmotors.in online platform. Share your preferences, and discover the best matches tailored to your specific requirements."
    }
  ];

  const state = useLocation();
  const [headers, setHeaders] = useState([]);
  const [value, setValue] = useState([]);
  const [cars, setCars] = useState([]);
  const [makes, setMakes] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(3);
  const [headerTitle, setHeader] = useState('Brands');
  const [faq] = useState(faqs);
  const navigate = useNavigate();
  const [showCars, setShowCars] = React.useState(false)
  const [carsByBrand, setCarsByBrand] = useState([]);




  async function fetchData() {
    try {
      const result = await fetchEvMake();
      console.log(result.message)
      setMakes(result.message);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function fetchModelData() {
    try {
      const result = await fetchEvModel();
      console.log(result.message)
      setCars(result.message);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function onClicked(props) {
    console.log("Render cars", props)
    try {
      const result = await fetchEvModelBySlug(props.make);
      console.log(result.message)
      const message = result.message
      if(message.length !== 0) {
        setCarsByBrand(result.message);
        setShowCars(true)
      }else{
        setShowCars(false)
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  function doSomething() {
    console.log("Nothing")
  }
  function clicked(props) {
    const { modelName, avgRating, carVariantId, priceRange, ratingDesc, image } = props;
    const state = { modelName, avgRating, carVariantId, priceRange, ratingDesc, image };
    console.log(state);
    //navigate(`/details`, { state: { state } })
  }

  function clickedModel(props) {
    console.log('clickedModel', props);
    const { carSlug, makeSlug, images, baseVariant } = props;
    const state = { carSlug, makeSlug, images, baseVariant };
    navigate(`/${makeSlug}/${carSlug}}`, { state: { state } })
  }

  const renderLayout = (items) => {
    let myItems = [];
    for (let it in items) {
      myItems.push(
        <li key={it}>
          <NavLink onClick={() => handleClick1(it, items[it].items)}>{items[it].title}</NavLink>
          <a onClick={() => handleClick1(it, items[it].items)}>
            {items[it].title}
          </a>
        </li>
      )
    }
    setHeaders(myItems);
    //return myItems;
  }
  const showmore = () => {
    setItemsToShow(cars.length)
  }

  const showless = () => {
    setItemsToShow(3)
  }

  const handleClick1 = (index, items) => {
    setHeader(data.electricCars.items[index].title)
    renderLi(items);
  }

  const renderLi = (items) => {
    const dataList = [];
    for (let i in items) {
      const data = items[i];
      dataList.push(
        <div className='product' key={i}>
          <div className='product-container'>
            <div className='product-container-img'>
              <img src={data.image} alt={data.name} onClick={() => clicked(data)} />
            </div>
            <div className='productInfo'>
              <div>{data.name}</div>
              <div className='productPrice'>
                {String.fromCharCode(0x20B9) + data.priceRange}
              </div>
            </div>
          </div>
        </div>
      )
    }
    setValue([dataList]);
  }

  const Cars = () => {
    return(
      <div>
      <h2 >{`${carsByBrand[0].make} Cars`}</h2>
      <div className='mainProducts'>
        {/* {value}
            <div>
              {makes.slice(0, itemsToShow).map((make, index) => <li key={index}>{make.make}</li>)}
              {(itemsToShow === 3) ? <button onClick={showmore}>Show More</button> : <button onClick={showless}>Show Less</button>}
            </div> */}
        {carsByBrand.map((car, index) =>
          <div className='product' key={index}>
            <div className='product-container'>
              <div className='product-container-img'>
                <img src={car.image} alt={car.name} onClick={() => car.baseVariant.length > 0 ? clickedModel(car) : doSomething()} />
                { car.isUpcoming === true && 
                <Badge className='badge' bg="" style={{backgroundColor: '#D6EFD8', color: '#1A5319'}}>Upcoming</Badge>
                }
              </div>
              <div className='productInfo'>
                <div>{car.name}</div>
                <div className='productPrice'>
                  {String.fromCharCode(0x20B9) + car.priceRange}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    )
  }

  const renderList = (items) => {
    <div className='product'>
      <div className='product-container'>
        <div className='product-container-img'>
          <img src={items.image} alt={items.name} onClick={() => clicked(items)} />
        </div>
        <div className='productInfo'>
          <div>{items.name}</div>
          {/* <div className='productPrice'>
                {String.fromCharCode(0x20B9) + data.priceRange}
              </div> */}
        </div>
      </div>
    </div>
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'NewCars';
    if (headers.length) return;
    fetchData();
    fetchModelData();
    setHeader(data.electricCars.items[0].title)
    renderLayout(data.electricCars.items);
    handleClick1(0, data.electricCars.items[0].items)
  }, [headers, value, headerTitle]);
  // }, []);
  // <div style={{
  //   display:'block'
  // }}>
  //   <li key={i}>
  //   {items[i].brandName}
  // </li>
  // </div>

  return (
    <div>
      <SEO
        title='New Cars'
        description={faq[0].ans}
      />
      <main>
        <div className='column_wrapper'>
          <h1>New cars in 2024</h1>
          <p>
            TheEVmotors brings a complete range of new cars in 2024 in India with prices stay tuned to the latest car news.
          </p>
        </div>


        <aside style={{
          "margin-left": 'auto',
          "margin-right": 'auto'
        }} className="adText">
          <p>Ad</p>
        </aside>

        <div style={{
          marginLeft: '0px'
        }}>
          {/* <h2 >{headerTitle}</h2> */}
          <h2 >Popular Brands</h2>

          {/* <div className='navHeader'> */}
          {/* <nav className='section-nav'>
              <ul>
                {headers}
              </ul>
            </nav> */}
          {/* <ul>
              {headers}
            </ul> */}
          {/* </div> */}
          <div className='mainProducts'>
            {makes.map((make, index) =>
              <div className='product' key={index}>
                {/* <div className='product-container'
                  style={{
                    width: '100px',
                    height: '100px',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                > */}
                <div className='grid-item'>
                  {/* <div className='product-container-img'>
                    
                  </div> */}
                  <img className='image' src={make.logoUrl} alt={make.name} onClick={() => onClicked(make)} />
                </div>
              </div>
            )}
          </div>

          <div>
            {showCars ? <Cars /> : null}
          </div>


          <h2 >Top Electric Cars</h2>
          <div className='mainProducts'>
            {/* {value}
            <div>
              {makes.slice(0, itemsToShow).map((make, index) => <li key={index}>{make.make}</li>)}
              {(itemsToShow === 3) ? <button onClick={showmore}>Show More</button> : <button onClick={showless}>Show Less</button>}
            </div> */}
            {cars.map((car, index) =>
              <div className='product' key={index}>
                <div className='product-container'>
                  <div className='product-container-img'>
                    <img src={car.image} alt={car.name} onClick={() => clickedModel(car)} />
                  </div>
                  <div className='productInfo'>
                    <div>{car.name}</div>
                    <div className='productPrice'>
                      {String.fromCharCode(0x20B9) + car.priceRange}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>

            {/* {cars.map((car, index) => <li key={index}>{car.name}</li>)} */}
          </div>
        </div>

        <div style={{
          marginLeft: '0px'
        }}>
          <h1>FAQs</h1>
          {
            faq.map(({ id, ques, ans }) => (
              <HydrogenFAQ id={id} title={ques} content={ans} />
            ))
          }
        </div>

      </main>

    </div>
  )

}