import React from 'react';
import ContentLoader from 'react-content-loader';

const ShimmerLoader = () => {
    return (
        <ContentLoader
            width={200}
            height={100}
            viewBox="0 0 200 100"
            backgroundColor="#f5f5f5"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="200" height="20" />
            <rect x="0" y="30" rx="5" ry="5" width="150" height="20" />
        </ContentLoader>
    );
};

export default ShimmerLoader;
