import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import './style.css';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { postBlog } from '../../../../actions';
import { fetchEvAllModel, fetchEvMake, fetchEvModel } from '../../../../helpers/axios';
import { generateCarSlug, generateSlug } from '../../../../helpers/createslug';



/**
* @author
* @function CreatePostPage
**/
//reactquill

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ]
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export const CreatePostPage = (props) => {
  const type = useSelector(state => state.post);

  // const [title, setTitle] = useState('');
  // const [fileName, setFileName] = useState('');
  // const [summary, setSummary] = useState('');
  // const [content, setContent] = useState('');
  // const [files, setFiles] = useState('');
  // const [base64URL, setbase64URL] = useState('');
  // const [ make, setMake ] = useState('');
  const [ formData, setFormData ] = useState({
    title: '',
    fileName: '',
    summary: '',
    files: '',
    base64URL: '',
    make: '',
    makeSlug: '',
    name: '',
    nameSlug: '',
    selectedOption: 'car',
  });

  const [ content, setContent ] = useState();
  const dispatch = useDispatch();
  const [ makes, setMakes ] = useState([]);

  useEffect(() => {
    // Fetch makes data on component mount
    const fetchMakes = async () => {
      try {
        const result = await fetchEvMake();
        console.log(result.message);
        setMakes(result.message);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchMakes();
  }, []);
  

  const [ models, setModels ] = useState([]);
  useEffect(() => {
    const fetchModels = async() => {
      try {
        const result = await fetchEvAllModel();
        const vehicles = result.message.filter(_ => _.make === formData.make)
        console.log('Model', vehicles);
        setModels(vehicles);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchModels()
  }, [ formData.make ])

  console.log('Make Input', formData.make, formData.makeSlug)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = type === 'checkbox' ? checked : value;
   
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [name]: updatedValue };

      if (name === 'name') {
        newFormData.nameSlug = generateCarSlug(updatedValue);
      }

      if (name === 'make') {
        const selectedMakeObj = makes.find(make => make.make === value);
        newFormData.makeSlug = selectedMakeObj ? generateSlug(selectedMakeObj.make) : '';
      }

      return newFormData;
    });
  };

  // const handleOptionChange = (event) => {
  //     setSelectedOption(event.target.value);
  // };

  //https://yarnpkg.com/package/react-quill


  const handleFileInputChange = (e) => {
    const file = e.target.files[0]

    setFormData((prevFormData) => ({
      ...prevFormData,
      fileName: file.name,
      files: file,  // Store file object in formData
    }));
  
    // Convert file to base64
    getBase64(file)
      .then(result => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          base64URL: result, // Store base64 URL in formData
        }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  function AlertDismissibleExample() {
    const [show, setShow] = useState(true);
    console.log("presentng");
    if (show) {
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>
            Change this and that and try again. Duis mollis, est non commodo
            luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
            Cras mattis consectetur purus sit amet fermentum.
          </p>
        </Alert>
      );
    }
    return <Button onClick={() => setShow(true)}>Show Alert</Button>;
  }


  function createNewPost(ev) {
    console.log('trigrred')
    ev.preventDefault();
    const data = new FormData();
    data.set('title', formData.title);
    data.set('summary', formData.summary);
    data.set('content', content);
    data.set('file', formData.files[0]);
    data.set('imagebase64', formData.base64URL);
    data.set('fileName', formData.fileName);
    data.set('newsType', formData.selectedOption);
    data.set('make', formData.make);
    data.set('makeSlug', formData.makeSlug);
    data.set('name', formData.name);
    data.set('nameSlug', formData.nameSlug);
    //console.log(files[0]);
    dispatch(postBlog(data))
    // axios.post(`${process.env.REACT_APP_SERVER_URL}/api/blog/createPost`, data)
    //   .then(function (response) {
        // window.location.reload();
    //   })
    //   .catch(function (error) {
    //     alert(error);
    //   });
  }

  function hideSpinner() {
    document.getElementById('post-span').innerHTML = 'Save Post';
    document.getElementById('post-spinner').hidden = 'true';
    document.getElementById('post_button').disabled = false;
  }


  function showSpinner() {
    document.getElementById('post-span').innerHTML = 'Saving...';
    document.getElementById('post-spinner').hidden = 'false';
    document.getElementById('post_button').disabled = true;
  }

  useEffect(() => {
    type.loading ? showSpinner() : hideSpinner()

    if (type.error) {
      hideSpinner()
      alert(type.error)
    }else{
      if(type.post) {
        alert(type.post)
        //window.location.reload();
      }
    }
    console.log(type.post)

  }, [type])

  return (
    <main className='form-container'>
      <form className='blog-form'>
        <div className='form-group'>
          <h3>Select an option:</h3>

          <div className="radio-group">
            <div className="radio-option">
                <input
                    type="radio"
                    id="car"
                    name="options"
                    value="car"
                    checked={formData.selectedOption === 'car'}
                    // onChange={handleOptionChange}
                    onChange={handleChange}
                />
                <label htmlFor="option1">Car</label>
            </div>
            <div className="radio-option">
                <input
                    type="radio"
                    id="bike"
                    name="options"
                    value="bike"
                    checked={formData.selectedOption === 'bike'}
                    onChange={handleChange}
                />
                <label htmlFor="option2">Bike</label>
            </div>
            <p>Selected Option: {formData.selectedOption}</p>
        </div>
        </div>

        <div className='form-group'>
          <select
              name="make"
              value={formData.make}
              onChange={handleChange}
            >
              <option value="">Select Make</option>
              {makes.map((make, index) => (
                <option key={index} value={make.make}>{make.make}</option>
              ))}
            </select>
        </div>

        <div className='form-group'>
          <select
              name="name"
              value={formData.name}
              onChange={handleChange}
            >
              <option value="">Select Model</option>
              {models.map((model, index) => (
                <option key={index} value={model.name}>{model.name}</option>
              ))}
            </select>
        </div>

        <div className='form-group'>
          <input 
            type="title"
            placeholder={'Title'}
            name="title"
            value={formData.title}
            // onChange={ev => setTitle(ev.target.value)} 
            onChange={handleChange} 
          />
        </div>

        <div className='form-group'>
          <input 
            type="summary"
            placeholder={'Summary'}
            value={formData.summary}
            name="summary"
            // onChange={ev => setSummary(ev.target.value)} 
            onChange={handleChange} 
          />
        </div>    
          {/* <input type="file"
          onChange={ev => setFiles(ev.target.files)} /> */}

        <div className='form-group'>
          <input type="file"
            onChange={handleFileInputChange} 
          />
        </div>

        <div className='form-group'>
          <ReactQuill
            value={content}
            onChange={newValue => setContent(newValue)}
            modules={modules}
            formats={formats} 
          />
        </div>

        <div className='form-group'>
          <Button id='post_button' style={{ marginTop: '5px' }} variant="primary" enabled onClick={createNewPost}>
            <Spinner id='post-spinner'
              hidden='true'
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span id='post-span'>
              Create Post
            </span>
          </Button>
        </div>  
          {/* <button style={{ marginTop: '5px' }}>
          Create post
        </button> */}
      </form>
    </main>

  )

}