import { formatISO9075 } from "date-fns";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Post({ title, summary, cover, postSlug, createdAt, userInfo }) {
    const navigate = useNavigate();
    console.log('userInfo', userInfo);
    const formatDate = (date) => {
        //const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
      };

    return (
        <main>
            <div className='post'>
                <div className='image'>
                    <Link onClick={(e) => {
                        e.preventDefault();
                        navigate(`/news_reviews/${postSlug}`)
                    }}>
                        <img src={`${process.env.REACT_APP_IMAGE_S3_URL}${cover}`} alt='Image not found' />
                    </Link>
                </div>
                <div className='texts'>
                    <Link onClick={(e) => {
                        e.preventDefault();
                        navigate(`/news_reviews/${postSlug}`)
                    }}>
                        <h2>{title}</h2>
                    </Link>
                    <p className='info'>
                        <a  style={{ fontSize: '10px'}} className='author'>By <span style={{ fontSize: '8px', color: 'GrayText' }}>{userInfo.username ? userInfo.username : 'Author'}</span></a>
                        {/* <time>{formatISO9075(new Date(createdAt))}</time> */}
                        <time>{formatDate(new Date(createdAt))}</time>
                    </p>
                    
                    <p className='summary'>{summary}</p>
                </div>
            </div>
        </main>

    )
}