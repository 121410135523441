import { useState } from "react"
import FeatureOrSpec from "../../FeatureOrSpec";

const SpecificationForm = () => {
    const [ formData, setFormData ] = useState({
        title: '',
        variantSlug: '',
        type: 'specification',
        items: [],
    });

    return (
        <FeatureOrSpec formData={formData} setFormData={setFormData} />
    )
}

export default SpecificationForm