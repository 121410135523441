import React from 'react'
import { Helmet } from 'react-helmet-async';

/**
* @author
* @function SEO
**/

export const SEO = ({ title, description, name, type, link }) => {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel='canonical' href={link} />
            <meta name="keywords" content="ev, ev car, ev cars, best ev cars, ev motor, ev motors, online ev cars, cars, car"></meta>
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            { /* End Twitter tags */}
        </Helmet>
    )

}