import React from 'react';
import { useNavigate } from 'react-router-dom';

const SignOut = ({ onSignOut }) => {
  
  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    onSignOut(); // Notify parent component on sign-out
  };

  const navigate = useNavigate(); // Use navigate for button actions

  const handleAddModelClick = () => {
    navigate('/add/model');
  };

  const handleAddVariantClick = () => {
    navigate('/add/model/variant');
  };


  return (
    <div className="signout-wrapper">
      <button onClick={handleSignOut} className="signout-button">
        Sign Out
      </button>
      <h2>Dashboard</h2>
      <div className="button-group">
        <button className="action-button" onClick={handleAddModelClick}>
          Add Model
        </button>
        <button className="action-button" onClick={handleAddVariantClick}>
          Add Variant
        </button>
      </div>
    </div>
  );
};

export default SignOut;
