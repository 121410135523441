import React, { useState, useEffect } from 'react';
import { fetchEvAllModel, performAddVariant } from '../../../helpers/axios';
import './VariantForm.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom'; // Only for React Router usage
import { handleAuthorizationError } from '../../../helpers/errorutils';
import { toast } from 'react-toastify'; // Import toast

const VariantForm = () => {
  const [formData, setFormData] = useState({
    text: '',
    name: '',
    title: '',
    price: '',
    fuelName: '',
    exShowRoomPrice: '',
    variantStatus: '',
    variantSlug: '',
    vehicleType: '',
    tag: '',
    modelSlug: '',
    brandSlug: '',
    isTrending: false,
    // featured: [],
    // specification: [],
  });
  const [cars, setCars] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;

    setFormData(prevData => {
      const updatedData = {
        ...prevData,
        [name]: updatedValue,
      };

      // Update variantSlug if name changes
      if (name === 'name') {
        const newVariantSlug = generateVariantSlug(updatedValue);
        updatedData.variantSlug = newVariantSlug;
      }

      if (name === 'modelSlug') {
        // Find the selected model based on the modelSlug
        const selectedModel = cars.find(car => car.name === value);

        if (selectedModel) {
          // Update formData with both modelSlug and make
          setFormData(prevFormData => ({
            ...prevFormData,
            modelSlug: value,
            brandSlug: selectedModel.makeSlug, // Automatically set the make based on the selected modelSlug
          }));
        }
      }

      return updatedData;
    });
  };

  async function fetchModelData() {
    try {
      const result = await fetchEvAllModel();
      console.log("fetchEvModel", result.message)
      setCars(result.message);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchModelData();
  }, []);

  const generateVariantSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, '_')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };

  // const handleNestedChange = (mainKey, index, e) => {
  //   const { name, value } = e.target;
  //   const updatedData = { ...formData };
  //   if (!updatedData[mainKey][index]) {
  //     updatedData[mainKey][index] = {};
  //   }
  //   updatedData[mainKey][index][name] = value;
  //   setFormData(updatedData);
  // };

  const handleNestedChange = (path, index, event) => {
    const { name, value, type, checked } = event.target;
    const updatedValue = type === 'checkbox' ? checked : value;
console.log('Field Name:', name);
  console.log('Field Type:', type);
  console.log('Checked:', checked);
  console.log('Value:', value);
  console.log('Updated Value:', updatedValue);
    setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData, [name]: updatedValue };

        const pathParts = path.split('.');
        let currentLevel = updatedFormData;

        for (let i = 0; i < pathParts.length; i++) {
            const part = pathParts[i];

            if (i === pathParts.length - 1) {
                // Initialize the array if it doesn't exist
                if (!Array.isArray(currentLevel[part])) {
                    currentLevel[part] = [];
                }
                currentLevel[part][index] = {
                    ...currentLevel[part][index],
                    [name]: updatedValue,
                };
            } else {
                // Initialize objects or arrays along the path if they don't exist
                if (!currentLevel[part]) {
                    currentLevel[part] = isNaN(pathParts[i + 1]) ? {} : [];
                }
                currentLevel = currentLevel[part];
            }
        }

        return updatedFormData;
    });
};


  const addFeature = () => {
    setFormData({
      ...formData,
      featured: [
        ...formData.featured,
        { title: '', items: [{ text: '', value: '', icon: '', desc: '', availabel: false, isTestedValue: false }] },
      ],
    });
  };

  const removeFeature = (index) => {
    const updatedFeatures = formData.featured.filter((_, i) => i !== index);
    setFormData({ ...formData, featured: updatedFeatures });
  };

  const addSpecification = () => {
    setFormData({
      ...formData,
      specification: [
        ...formData.specification,
        { title: '', items: [{ text: '', value: '', icon: '', desc: '', availabel: false, isTestedValue: false }] },
      ],
    });
  };

  const removeSpecification = (index) => {
    const updatedSpecifications = formData.specification.filter((_, i) => i !== index);
    setFormData({ ...formData, specification: updatedSpecifications });
  };

  const addItem = (path, index) => {
    const updatedData = { ...formData };
    const [mainKey, subKey] = path.split('.');
    updatedData[mainKey][index][subKey].push({ text: '', value: '', icon: '', desc: '', availabel: false, isTestedValue: false });
    setFormData(updatedData);
  };

  const removeItem = (path, parentIndex, itemIndex) => {
    const updatedData = { ...formData };
    const [mainKey, subKey] = path.split('.');
    updatedData[mainKey][parentIndex][subKey] = updatedData[mainKey][parentIndex][subKey].filter((_, i) => i !== itemIndex);
    setFormData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Varaint Form', formData);
    // Submit formData to the backend or handle as needed
    postVariant(formData);
  };

  const postVariant = async (formData) => {
    try {
      const result = await performAddVariant(formData);
      console.log(result.message); // Logging fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
      try {
        handleAuthorizationError(error, navigate);
      } catch (e) {
        // Handle non-authorization errors here
        toast.error("An error occurred: " + (error.response?.data?.message || "Please try again later."));
      }
    }
  };

  return (
    <div className="form-container">
      <form className="variant-form" onSubmit={handleSubmit}>
        <h1>Add Variant</h1>

        {/* Basic fields */}
        <div className="form-group">
          <label>Model:</label>
          <select
            name="modelSlug"
            value={formData.modelSlug}
            onChange={handleChange}
          >
            <option value="">Select a model</option>
            {cars.map((car) => (
              <option key={car.name} value={car.name}>
                {car.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Text:</label>
          <input type="text" name="text" value={formData.text} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Title:</label>
          <input type="text" name="title" value={formData.title} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Price:</label>
          <input type="text" name="price" value={formData.price} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Fuel Name:</label>
          <select
            name="fuelName"
            value={formData.fuelName}
            onChange={handleChange}
          >
            <option value="">Select a fuel type</option>
            <option value="Electric">Electric</option>
            <option value="Hybrid">Hybrid</option>
            <option value="Hydrogen">Hydrogen</option>
          </select>
        </div>
        <div className="form-group">
          <label>Ex-Showroom Price:</label>
          <input type="number" name="exShowRoomPrice" value={formData.exShowRoomPrice} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Variant Status:</label>
          <input type="text" name="variantStatus" value={formData.variantStatus} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Variant Slug:</label>
          <input type="text" name="variantSlug" value={formData.variantSlug} readOnly />
        </div>
        <div className="form-group">
          <label>Vehicle Type:</label>
          <input type="text" name="vehicleType" value={formData.vehicleType} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Tag:</label>
          <select
            name="tag"
            value={formData.tag}
            onChange={handleChange}
          >
            <option value="">Select a tag</option>
            <option value="Base model">Base model</option>
            <option value="">None</option>
            <option value="Top model">Top model</option>
          </select>
        </div>

        <div className="form-group">
          <label>Brand Slug:</label>
          <input type="text" name="brandSlug" value={formData.brandSlug} onChange={handleChange} readOnly />
        </div>
        <div className="form-group">
          <label>Is Trending:</label>
          <input type="checkbox" name="isTrending" checked={formData.isTrending} onChange={handleChange} />
        </div>

        {/* Featured */}
        {/* <div className="form-section">
          <h3>Featured</h3>
          {formData.featured.map((feature, featureIndex) => (
            <div key={featureIndex} className="nested-section">
              <div className="form-group">
                <label>Feature Title:</label>
                <input
                  type="text"
                  name="title"  // Set this to match the key in your state object
                  value={feature.title}
                  onChange={(e) => handleNestedChange('featured', featureIndex, e)}
                />
              </div>
              <div>
                <h4>Items</h4>
                {feature.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="nested-item">
                    <div className="form-group">
                      <label>Text:</label>
                      <input type="text" name='text' value={item.text} onChange={(e) => handleNestedChange(`featured.${featureIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Value:</label>
                      <input type="text" name='value' value={item.value} onChange={(e) => handleNestedChange(`featured.${featureIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Icon:</label>
                      <input type="text" name='icon' value={item.icon} onChange={(e) => handleNestedChange(`featured.${featureIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Description:</label>
                      <input type="text" name='description' value={item.desc} onChange={(e) => handleNestedChange(`featured.${featureIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Available:</label>
                      <input type="checkbox" name='availabel' checked={item.availabel} onChange={(e) => handleNestedChange(`featured.${featureIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Is Tested Value:</label>
                      <input type="checkbox" name='isTestedValue' checked={item.isTestedValue} onChange={(e) => handleNestedChange(`featured.${featureIndex}.items`, itemIndex, e)} />
                    </div>
                    <button type="button" className="remove-item" onClick={() => removeItem('featured.items', featureIndex, itemIndex)}>Remove Item</button>
                  </div>
                ))}
                <button type="button" className="add-item" onClick={() => addItem('featured.items', featureIndex)}>Add Item</button>
              </div>
              <button type="button" className="remove-feature" onClick={() => removeFeature(featureIndex)}>Remove Feature</button>
            </div>
          ))}
          <button type="button" onClick={addFeature}>Add Feature</button>
        </div> */}

        {/* Specifications */}
        {/* <div className="form-section">
          <h3>Specifications</h3>
          {formData.specification.map((spec, specIndex) => (
            <div key={specIndex} className="nested-section">
              <div className="form-group">
                <label>Specification Title:</label>
                <input type="text" name='title' value={spec.title} onChange={(e) => handleNestedChange('specification', specIndex, e)} />
              </div>
              <div>
                <h4>Items</h4>
                {spec.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="nested-item">
                    <div className="form-group">
                      <label>Text:</label>
                      <input type="text" name='text' value={item.text} onChange={(e) => handleNestedChange(`specification.${specIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Value:</label>
                      <input type="text" name='value' value={item.value} onChange={(e) => handleNestedChange(`specification.${specIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Icon:</label>
                      <input type="text" name='icon' value={item.icon} onChange={(e) => handleNestedChange(`specification.${specIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Description:</label>
                      <input type="text" name='description' value={item.desc} onChange={(e) => handleNestedChange(`specification.${specIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Available:</label>
                      <input type="checkbox" name='available' checked={item.availabel} onChange={(e) => handleNestedChange(`specification.${specIndex}.items`, itemIndex, e)} />
                    </div>
                    <div className="form-group">
                      <label>Is Tested Value:</label>
                      <input type="checkbox" name='isTestedValue' checked={item.isTestedValue} onChange={(e) => handleNestedChange(`specification.${specIndex}.items`, itemIndex, e)} />
                    </div>
                    <button type="button" className="remove-item" onClick={() => removeItem('specification.items', specIndex, itemIndex)}>Remove Item</button>
                  </div>
                ))}
                <button type="button" className="add-item" onClick={() => addItem('specification.items', specIndex)}>Add Item</button>
              </div>
              <button type="button" className="remove-feature" onClick={() => removeSpecification(specIndex)}>Remove Specification</button>
            </div>
          ))}
          <button type="button" onClick={addSpecification}>Add Specification</button>
        </div> */}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default VariantForm;
