export const postConstants = {
    POST_REQUEST: 'POST_REQUEST',
    POST_FAILURE: 'POST_FAILURE',
    GET_POST_REQUEST: 'GET_POST_REQUEST',
    GET_POST_SUCCESS: 'GET_POST_SUCCESS',
    GET_POST_FAILURE: 'GET_POST_FAILURE',
    GET_POST_BY_SLUG_REQUEST: 'GET_POST_BY_SLUG_REQUEST',
    GET_POST_BY_SLUG_SUCCESS: 'GET_POST_BY_SLUG_SUCCESS',
    GET_POST_BY_SLUG_FAILURE: 'GET_POST_BY_SLUG_FAILURE',
    POST_BLOG_REQUEST: 'POST_BLOG_REQUEST',
    POST_BLOG_SUCCESS: 'POST_BLOG_SUCCESS',
    POST_BLOG_FAILURE: 'POST_BLOG_FAILURE',
}