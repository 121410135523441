import React from 'react'
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '../BreadCrumbs/Breadcrumbs';
import { Footer } from '../Footer/footer';
import Header from '../Header';
import './style.css';
/**
* @author
* @function Layout
**/

const Layout = () => {
  return (
    <div className="layout">
          <Header />
            <div className="content">
              <Outlet />
            </div>
          <Breadcrumbs />
          <Footer />
      </div>

  )

}

export default Layout;