import React, { useState } from "react";
import "./GridItems.css";
import { useNavigate } from "react-router-dom";



const GridItems = ({ items }) =>  {
  const [visibleItemsCount, setVisibleItemsCount] = useState(8); // Initially show 2 rows (8 items)

  const loadMore = () => {
    setVisibleItemsCount((prev) => prev + 8); // Load 2 more rows (8 items)
  };


 // const visibleItems = initialItems.slice(0, visibleItemsCount);
  const visibleItems = items.slice(0, visibleItemsCount);
  // console.log(visibleItems);
  const hasMoreItems = visibleItemsCount < items.length;
  const navigate = useNavigate();


  function clicked(props) {
      console.log("clicked", props)
    const { carSlug, makeSlug, make, makesId } = props;
    const state = { makeSlug, make, makesId };
    navigate(`/${makeSlug}`, { state: { state } })
  }

  return (
    <div className="main">
      <div className="grid-container">
        {visibleItems.map((make, index) => (
          <div key={index} className="grid-item">
               <img src={make.logoUrl} alt={make.name} className="image" onClick={() => clicked(make)} />
        </div>
        ))}
        {hasMoreItems && (
          <div className="grid-item view-more-container">
            <button onClick={loadMore} className="view-more">
              View More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GridItems;
