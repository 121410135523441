import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDistance, format, formatISO9075, subDays } from "date-fns";
import './style.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import ReactGA from 'react-ga';
import { getPostBySlug } from "../../../../actions";
import { useDispatch, useSelector } from 'react-redux';
import { SEO } from "../../SEO";
import { fetchPopularPosts } from "../../../../helpers/axios";
import BlogCard from "../BlogCard/BlogCard";
import BlogList from "./BlogList";



export default function PostPage() {
    const post = useSelector(state => state.post.post);
    //const [postInfo, setPostInfo] = useState(null);
    const { slug } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);


    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            This blog does not take credit for any images unless stated otherwise. All images are copyrighted to their respective owners. If you own an image displayed here and want it removed, please email us with the image link, and we will promptly take it down.
        </Tooltip>
    );

    async function getPopular() {
        try {
            const result = await fetchPopularPosts();
            console.log("result", result)
            setItems(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        console.log(slug)
        ReactGA.pageview(location.pathname + location.search);
        dispatch(getPostBySlug(slug));
        getPopular();
        // axios.get(`${process.env.REACT_APP_SERVER_URL}/api/blog/getPost/${id}`).then(response => {
        //     if(response.data.message){
        //         setPostInfo(response.data.message);
        //     }
        // }).catch(error => {
        //     console.log(error);
        // });
    }, []);

    const handleBlogClick = (blog) => {
        console.log(blog);
        setSelectedBlog(blog);
    };

    const renderPost = (postInfo) => {

        if (!postInfo) {
            return <div></div>
        }
        return (
            // <div className="post-page">
            //      <SEO
            //      title={postInfo.title}
            //      description={postInfo.summary}
            //     link={window.location.href}
            //     />
            //     <h1>{postInfo.title}</h1>
            //     <div className="post-container">
            //         {/* <i className="fa fa-eye" style={{ margin: '0 3px', fontSize: '12px' }}></i> <span style={{ marginRight: '10px', fontSize: '10px' }}>{postInfo.totalViews}</span> */}
            //         <time>{format(new Date(postInfo.createdAt), "yyyy-MM-dd M:H a")}</time>
            //     </div>
            //     <div className="image">
            //         <img src={`${process.env.REACT_APP_IMAGE_S3_URL}${postInfo.cover}`} alt='' />
            //     </div>
            //     <div dangerouslySetInnerHTML={{ __html: postInfo.content }} />
            // </div>
            <div className="post-page">
                <SEO
                    title={postInfo.title}
                    description={postInfo.summary}
                    link={window.location.href}
                />
                <div className="post-container">
                    <div className="left-content">
                    <h1>{selectedBlog ? selectedBlog.title : postInfo.title}</h1>
                    <div className="post-meta">
                <time>{format(new Date(postInfo.createdAt), "yyyy-MM-dd MM:HH a")}</time>
            </div>
            <div className="image">
                <img
                    src={`${process.env.REACT_APP_IMAGE_S3_URL}${selectedBlog ? selectedBlog.cover : postInfo.cover}`}
                    alt={selectedBlog ? selectedBlog.title : postInfo.title}
                />
            </div>
            <div dangerouslySetInnerHTML={{ __html: selectedBlog ? selectedBlog.content : postInfo.content }} />

                       
                    </div>
                    <div className="right-content">
                        <section className="popular-stories">
                            <h3>Popular Stories</h3>
                            <BlogList items={items} onBlogClick={handleBlogClick} />
                        </section>
                        {/* <section className="popular-cars">
                            <h3>Popular Cars</h3>
                            <ul>
                                <li><a href="#">Popular Car 1</a></li>
                                <li><a href="#">Popular Car 2</a></li>
                                <li><a href="#">Popular Car 3</a></li>
                            </ul>
                        </section> */}
                    </div>
                </div>
            </div>

        )
    }

    // if (!post) return '';
    // if (process.env.NODE_ENV !== 'development') {
    //     axios.post(`${process.env.REACT_APP_SERVER_URL}/api/blog/${post._id}/${post.totalViews + 1}`).then(response => {
    //         if (response) {
    //             console.log(response)
    //         }
    //     }).catch(error => {
    //         console.log(error);
    //     });
    // }


    return (
        <div className="main-div">

            <main className="blog-main">
                {renderPost(post)}
            </main>

            {/* <div className="post-float">
                <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <Button variant="success">*Disclaimer</Button>
            </OverlayTrigger>
            </div> */}
        </div>



    )
}