import React from 'react';
import './Shimmer.css';

const Shimmer = () => {
  return (
    <div className="shimmer-container">
      <div className="shimmer-item">
        <div className="shimmer-image"></div>
        <div className="shimmer-details">
          <div className="shimmer-title"></div>
          <div className="shimmer-description"></div>
          <div className="shimmer-price"></div>
        </div>
      </div>
      {/* Repeat shimmer-item for the number of placeholders you want */}
      <div className="shimmer-item">
        <div className="shimmer-image"></div>
        <div className="shimmer-details">
          <div className="shimmer-title"></div>
          <div className="shimmer-description"></div>
          <div className="shimmer-price"></div>
        </div>
      </div>
      {/* Repeat shimmer-item for the number of placeholders you want */}
      <div className="shimmer-item">
        <div className="shimmer-image"></div>
        <div className="shimmer-details">
          <div className="shimmer-title"></div>
          <div className="shimmer-description"></div>
          <div className="shimmer-price"></div>
        </div>
      </div>
      {/* Repeat shimmer-item for the number of placeholders you want */}
      <div className="shimmer-item">
        <div className="shimmer-image"></div>
        <div className="shimmer-details">
          <div className="shimmer-title"></div>
          <div className="shimmer-description"></div>
          <div className="shimmer-price"></div>
        </div>
      </div>
      {/* Repeat shimmer-item for the number of placeholders you want */}
      <div className="shimmer-item">
        <div className="shimmer-image"></div>
        <div className="shimmer-details">
          <div className="shimmer-title"></div>
          <div className="shimmer-description"></div>
          <div className="shimmer-price"></div>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
