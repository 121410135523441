import React, { useState, useEffect } from 'react';
import './style.css';
import HydrogenFAQ from '../HydrogenCars/Accordin/HydrogenFAQ';
import Badge from 'react-bootstrap/Badge';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { SEO } from '../SEO';

/**
* @author
* @function HydrogenCarsPage
**/

export const HydrogenCarsPage = (props) => {
    const cars = [
        {
            name: "Toyota Mirai",
            price: "13.89 Lakh",
            unit: '85BB65',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAUZK5ibm7O6JfJ5pXtOC0VL2s8HMLuyQ8xnn6nI-oebsS5FAcfpSA68P1&s=10',
            isNewLaunched: true,
            isExpected: true
        },
        {
            name: "Hyundai Nexo",
            price: "60.89 Lakh",
            unit: '85BB65',
            image: 'https://s7g10.scene7.com/is/image/hyundaiautoever/nexo-keyvisual-2:16x9?wid=2080&hei=1170&fit=wrap,1',
            isNewLaunched: true,
            isExpected: false
        },

        {
            name: "BMW IX5",
            price: "15.89 Lakh",
            unit: '85BB65',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNXu4iZOOML3i4oFde1DFTJq_8-k0HGI7nbA&usqp=CAU',
            isNewLaunched: true,
            isExpected: false
        },
        {
            name: "Hyundai N Vision",
            price: "15.89 Lakh",
            unit: '85BB65',
            image: 'https://www.hyundai-n.com/images/en/model/nvision74-section01-img02-01.jpg',
            isNewLaunched: false,
            isExpected: false

        },
        {
            name: "Namx HUV",
            price: "15.89 Lakh",
            unit: '85BB65',
            image: 'https://pininfarina.it/en/attachment/namx-02/',
            isNewLaunched: true,
            isExpected: false

        },
        {
            name: "Alpine A4810",
            price: "15.89 Lakh",
            unit: '85BB65',
            image: 'https://www.renaultgroup.com/wp-content/uploads/2022/03/img22.jpg',
            isNewLaunched: false,
            isExpected: false

        },
        {
            name: "H2X Warraego",
            price: "15.89 Lakh",
            unit: '85BB65',
            image: 'https://h2xglobal.com/web/wp-content/uploads/2022/07/The-Warrego-1-1.jpg',
            isNewLaunched: true,
            isExpected: false

        },
        {
            name: "Honda Clarity",
            price: "15.89 Lakh",
            unit: '85BB65',
            image: 'https://wieck-honda-production.s3.amazonaws.com/photos/eaa0c2f0e0c3db9def0efc84e0219e32bb0ec557/preview-928x522.jpg',
            isNewLaunched: true,
            isExpected: false

        },
        {
            name: "Land rover Defender FCGV",
            price: "15.89 Lakh",
            unit: '85BB65',
            image: 'https://images.squarespace-cdn.com/content/v1/5e1e8a13e8a3102d5c82d1b1/1623720214375-J92OGL39TTYGQLPB5VS3/IMG_4923Defender+MAIN2.jpg',
            isNewLaunched: false,
            isExpected: false

        }
    ];

    const faqs = [
        {
            "id":"0",
            "ques":"What is a hydrogen vehicle?",
            "ans":"A hydrogen vehicle, or fuel cell vehicle (FCV), is an electric vehicle that uses hydrogen gas as a fuel to generate electricity through a fuel cell."
        },{
            "id":"1",
            "ques":"How do hydrogen vehicles generate power? ",
            "ans":"Hydrogen undergoes an electrochemical reaction in the fuel cell, splitting into protons and electrons, generating electricity to power an electric motor."
        },{
            "id":"2",
            "ques":"What is the role of the fuel cell stack?",
            "ans":"The fuel cell stack contains multiple individual fuel cells where hydrogen reacts with oxygen, producing electricity and water vapor as byproducts."
        },{
            "id":"3",
            "ques":"How is hydrogen stored in the vehicle?",
            "ans":"Hydrogen is stored in high-pressure tanks onboard the vehicle, designed to safely contain compressed hydrogen gas."
        },{
            "id":"4",
            "ques":"What emissions do hydrogen vehicles produce?",
            "ans":"Hydrogen vehicles emit only water vapor from the tailpipe, making them a clean and environmentally friendly transportation option."
        },{
            "id":"5",
            "ques":"What is the efficiency of hydrogen vehicles?",
            "ans":"The efficiency depends on factors like hydrogen production, distribution, and storage. Overall, hydrogen vehicles offer a clean and efficient alternative to traditional internal combustion engines."
        },{
            "id":"5",
            "ques":"Are there challenges to the widespread adoption of hydrogen vehicles?",
            "ans":"Challenges include the need for a hydrogen infrastructure, cost of production, and efficiency improvements in the entire hydrogen fuel cycle."
        }
    ];
    const [cars_list] = useState(cars);
    const [faq] = useState(faqs);
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return (
        <div className='hydrogen'>
            <SEO
      title='Hydrogen Cars'
      description={faq[0].ans}
      />
            <section className='billboard'>
                {/* <div className='home-text'>
                    <h1>2023 Mirai</h1>
                    <p style={{color:'#172554',background:'white'}}>Hydrogen car generates power by combining hydrogen with oxygen from the outside air.</p>
                </div> */}
            </section>

            <div style={{
                width:'95%',
                margin: '0 auto',
                marginTop: '10px',
                marginBottom: '10px'

            }}>
            <div className='card' >
                <header className='cardHeader'>
                    <h2>Upcoming Hydrogen Cars</h2>
                </header>
                <div className='mainProducts'>
                    {
                        cars_list.map((data, index) =>
                            <div className='product' key={index}>
                                <div className='product-container'>
                                    <div className='box'>
                                        {data.isExpected === true ? <Badge className='badge' bg="warning">Expected in India</Badge> : ""}
                                        <img src={data.image} style={{objectPosition:'start', objectFit:'fill'}} alt='' />
                                        <h2>{data.name}</h2>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <section className='cardHeader'>
                <h2>FAQs?</h2>
                {
                    faq.map(({id,ques,ans})=> (
                        <HydrogenFAQ id={id} title={ques} content={ans} />
                    ))
                }
            </section>
            </div>
            


        </div>
    )

}
