import React, { useCallback, useEffect, useState } from 'react';
import { performAddLead } from '../../../helpers/axios';
import './leadStyle.css';

const PopupForm = ({ car, onClose }) => { // onClose prop for closing the form
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState({ name: '', phoneNumber: '' });
  const [message, setMessage] = useState(''); // State for the success message


  const validate = () => {
    let valid = true;
    const newErrors = { name: '', phoneNumber: '' };

    // Validate name
    if (!name.trim()) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    // Validate phone number
    const phonePattern = /^[0-9]{10}$/; // Adjust pattern according to your needs
    if (!phonePattern.test(phoneNumber)) {
      newErrors.phoneNumber = 'Phone number must be 10 digits';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const isSubmitDisabled = useCallback(() => {
    return !(name.trim() && phoneNumber.trim());
  }, [name, phoneNumber]);

    const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("Phone Number:", car.name);
    handleLead(car)
  };


  const handleLead = async (car) => {
    const formData = {
        name,
        phoneNumber,
        cars: [
          {
            name: car.name,
            carSlug: car.carSlug,
            brand: car.make,
          },
        ],
      };  
    try {
        const result = await performAddLead(formData);
        console.log(result.message); // Logging fetched data
        setMessage('The team will get in touch with you'); // Set success message
      // You might want to disable the form inputs and button here if needed
      // e.g., setFormDisabled(true);
      setTimeout(onClose, 2000); // Optional: Close the popup after 2 seconds
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };
  // Update validation state whenever input changes
  useEffect(() => {
    validate();
  }, [name, phoneNumber]);

  return (
    <div className="popup-overlay">
      <div className="popup-content1">
      <button className="back-button" title='Back' onClick={onClose}>
          &larr; {/* Back arrow icon */}
        </button>
        <h2>Enter Your Details for offers on {car.name}</h2> {/* Display car name */}
        {message ? (
          <div className="success-message">
            {message}
          </div>
        ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              className="input-field"
            />
          </div>
          <button type="submit" className="button" disabled={isSubmitDisabled()}>Submit</button>
        
        </form>
        )}
      </div>
    </div>
  );
};

export default PopupForm;
