import { useState } from "react";
import FeatureOrSpec from "../../FeatureOrSpec";

const FeaturedForm = () => {
    const [ formData, setFormData ] = useState({
        title: '',
        type: 'feature',
        variantSlug: '',
        items: [],
    });

    return (
        <FeatureOrSpec formData={formData} setFormData={setFormData} />
    )
}

export default FeaturedForm