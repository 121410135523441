import React, { useEffect } from "react";
import './menu_style.css';
import clsx from "clsx";
import { FaCheck, FaTimes } from 'react-icons/fa';



const MenuItem = ({ item: { title, items = [], href }, isFirstItem }) => {
    const [expanded, setExpanded] = React.useState(isFirstItem);
    const hasSubmenu = items.length > 0;
    const renderSubmenu = expanded && hasSubmenu;
    const toggle = React.useCallback(() => setExpanded(!expanded), [expanded]);

    // Automatically expand the first item
    useEffect(() => {
        if (isFirstItem) {
            setExpanded(true);
        }
    }, [isFirstItem]);

    return (
        <li className={clsx("menu-item", { expanded, hasSubmenu })}>
            <div className="menu-item-title" onClick={hasSubmenu ? toggle : null}>
                {title}
            </div>
            {renderSubmenu && <SubMenu items={items} />}
        </li>
    );
};

const Menu = ({ items }) => {
    return !items?.length ? null : (
        <ul className="menu">
            {items.map((item, index) => (
                <MenuItem key={item.title} item={item} isFirstItem={index === 0} />
            ))}
        </ul>
    );
};

const SubMenu = ({ items }) => {
    return !items?.length ? null : (
        <ul className="menu">
            <table>
                <tbody>
                    {items.map((item) => (
                        <tr key={item.title}>
                            <th>{item.text}</th>
                            <td className="centered-cell">
                            {
                                item.value.toLowerCase() === 'yes' ? (
                                    <FaCheck style={{ color: 'green' }} />
                                ) : item.value.toLowerCase() === 'no' ? (
                                    <FaTimes style={{ color: 'red' }} />
                                ) : (
                                    item.value
                                )
                            }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ul>
    );
};

export default Menu;

