import React from 'react'

/**
* @author
* @function DisclaimerPage
**/

export const DisclaimerPage = (props) => {
    return (
        <div>
            <main>
                <p><strong>Disclaimer</strong></p>
                <p><br /></p>
                <p><strong>1. Content Accuracy:</strong></p>
                <p>The information provided on Theevmotors.in is for general informational purposes only. We strive to ensure the accuracy of the content, but we do not guarantee its completeness or timeliness.</p>
                <p><strong>2. User-Generated Content:</strong></p>
                <p>Reviews and ratings posted by users on Theevmotors.in are personal opinions. We do not endorse or verify the accuracy of user-generated content. Users are encouraged to verify information independently.</p>
                <p><strong>3. Product Representation:</strong></p>
                <p>Theevmotors.in does not manufacture or sell electric vehicles. Product specifications, features, and availability may change without notice. We recommend checking with the respective manufacturers for the latest information.</p>
                <p><strong>4. Affiliate Links:</strong></p>
                <p>Some links on Theevmotors.in may be affiliate links. If users make a purchase through these links, we may earn a commission. However, this does not impact the content or reviews provided.</p>
                <p><strong>5. Professional Advice:</strong></p>
                <p>Information on Theevmotors.in is not intended as professional advice. Users should consult with relevant experts or professionals before making significant decisions related to electric vehicles.</p>
                <p><strong>6. Availability and Accessibility:</strong></p>
                <p>Theevmotors.in strives to ensure the availability and accessibility of the website. However, we do not guarantee uninterrupted access or that the website is free from errors or viruses.</p>
                <p><strong>7. Third-Party Websites:</strong></p>
                <p>Theevmotors.in may contain links to third-party websites. We are not responsible for the content, privacy practices, or actions of these websites. Users should review the terms and privacy policies of external sites.</p>
                <p><strong>8. Changes to Disclaimer:</strong></p>
                <p>Theevmotors.in reserves the right to update or modify this disclaimer at any time. Users are encouraged to review this disclaimer periodically for changes.</p>
                <p><strong>9. User Responsibility:</strong></p>
                <p>Users are responsible for their interactions and engagements with the content on Theevmotors.in. We recommend users independently verify information and exercise discretion.</p>
                <p><strong>10. Contact Information:</strong></p>
                <p>For inquiries or concerns regarding this disclaimer or any content on Theevmotors.in, please contact us at <a data-fr-linked="true" href="mailto:Info@evmotors.in">Info@evmotors.in</a>.</p>
                <p>By using Theevmotors.in, users acknowledge and agree to the terms outlined in this disclaimer.</p>
                <p><br /></p>
                <p>It&apos;s important to tailor disclaimers to your specific website and consult with legal professionals to ensure they align with applicable laws and regulations.</p>
            </main>
        </div>
    )

}