import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import { HomePage, NewCarPage, CreatePostPage, HydrogenCarsPage, HybridCarsPage, AboutUsPage, ProductDetailsPage } from './components/Pages';
import PostPage from './components/Pages/Blog/PostPage';
import ReactGA from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { TermConditionPage } from './components/Pages/TermConditions';
import { DisclaimerPage } from './components/Pages/Disclaimer';
import NotFound from './containers/404Page';
import BlogPage from './components/Pages/Blog';
import ListWithTabBar from './components/Pages/HomePage/FeaturedCars/featuredCars';
import GridItems from './components/Pages/example/GridItems';
import CarList from './components/Pages/CarList/CarList';
import BikeBlogPage from './components/Pages/Blog/bikeBlog';
import ModelForm from './components/Pages/ModelForm/ModelForm';
import VariantForm from './components/Pages/VariantForm/VariantForm';
import LocationForm from './components/Pages/VariantForm/LocationForm';
import GeolocationComponent from './components/Pages/VariantForm/LocationForm';
import LoginPage from './components/Pages/Login/LoginPage';
import BlogPage1 from './components/Pages/Blog/Test/BlogPage';
import Dashboard from './components/Pages/Dashboard/dashboard';
import FeaturedForm from './components/Pages/FeaturedForm/FeaturedForm';
import SpecificationForm from './components/Pages/SpecificationForm/SpecificationForm';



function App() {
  const helmetContext = {};
  var host = window.location.hostname;
      if(host !== "localhost"){
        ReactGA.initialize('GTM-58S3QWZF');
      }
  return (
    <HelmetProvider context={helmetContext}>
      <Routes>
        <Route path='*' element={<NotFound />}/>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          {/* <Route index element={<ListWithTabBar />} /> */}
          <Route path='/newcars' element={<NewCarPage />} />
          <Route path='/hybrid_cars' element={<HybridCarsPage />} />
          <Route path='/:slug' element={<CarList />} />
          <Route path='/createblog' element={<CreatePostPage />} />
          <Route path='/news_reviews' element={<BlogPage />} />
          <Route path='/bike/news_reviews' element={<BikeBlogPage />} />
          <Route path='/hydrogen_cars' element={<HydrogenCarsPage />} />
          <Route path='/news_reviews/:slug' element={<PostPage />} />
          <Route path='/aboutus' element={<AboutUsPage />} />
          <Route path='/disclaimer' element={<DisclaimerPage />} />
          <Route path='/termandconditions' element={<TermConditionPage />} />
          <Route path='/:make/:carSlug' element={<ProductDetailsPage />} />
          <Route path='/add/model' element={<ModelForm />} />
          <Route path='/add/model/variant' element={<VariantForm />} />
          <Route path='/add/model/variant/featured' element={<FeaturedForm />} />
          <Route path='/add/model/variant/specification' element={<SpecificationForm />} />
          <Route path='/location' element={<GeolocationComponent />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
