import React from 'react';
import './footerstyle.css';

/**
* @author
* @function Footer
**/

export const Footer = (props) => {
    return (
        <footer id='footer' className='main-footer clearfix'>
            <div>
            </div>
            <div>
                <p style={{ fontSize: '10px'}}><a href='/disclaimer'>Disclaimer:</a> This Site does not take credit for any images unless stated otherwise. All images are copyrighted to their respective owners. If you own an image displayed here and want it removed, please email us with the image link, and we will promptly take it down.</p>
            </div>
            <p className='copywrite'>©2024 TheEVmotors Pvt Ltd</p>
            <ul className='footer-nav clearfix'>
                <li><a href='/'>Home</a></li>
                <li><a href='/aboutus'>About us</a></li>
                <li><a href='https://www.privacypolicies.com/live/feb0405e-f1bc-4404-97eb-191953c2253e'>Policy</a></li>
                <li><a href='termandconditions'>Terms of use</a></li>
            </ul>
            <ul className='footer-nav clearfix'>
                <li>
                <i className="glyphicon glyphicon-envelope" title=''>Email: info@theEVmotors.in </i>
                </li>
            </ul>
        </footer>
    )

}