import React, { useState, useEffect } from 'react';
import './List.css';
import Shimmer from './Shimmer';
import { fetchEvModelBySlug } from '../../../helpers/axios';
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';


const List = ({ items }) => {
    const [loading, setLoading] = useState(true);
    const [carsByBrand, setCarsByBrand] = useState([]);
    const navigate = useNavigate();

    async function getData(props) {
        console.log("props", props)
        try {
            const result = await fetchEvModelBySlug(props);
            console.log(result.message)
            const message = result.message
            if(message.length !== 0) {
              setCarsByBrand(result.message);
            }else{
                <div>No Data found</div>
            }
            setLoading(false)
            //else{
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    }

    function clicked(props) {
        const { carSlug, makeSlug, images, baseVariant } = props;
        const state = { carSlug, makeSlug, images, baseVariant };
        navigate(`/${makeSlug}/${carSlug}`, { state: { state } })
    }

    function doSomething() {
        console.log("Nothing")
    }

    useEffect(() => {
        // Simulate data fetching
        setTimeout(() => {
            setLoading(false);
        }, 1000); // Adjust based on your data fetching time
        //getData(items)
    }, []);

    if (loading) {
        return <Shimmer />;
    }
    return (
        <div className="list-wrapper">
            <div className="list-container">
                {items.map((item, index) => (
                    <div key={index} className="list-item" onClick={() => item.baseVariant.length > 0 ? clicked(item) : doSomething()}>
                        <img src={item.image} alt={item.title} className="item-image" />
                        { item.isUpcoming === true && 
                            <Badge className='badge' bg="" style={{backgroundColor: '#D6EFD8', color: '#1A5319'}}>Upcoming</Badge>
                        }
                        <div className="item-details">
                            <h2 className="item-title">{item.title}</h2>
                            { item.isUpcoming === true && 
                            <p className="upcoming-description">Upcoming</p>
                            }
                            <p className="item-description"> <span className="asterisk">*</span>Ex-Showroom</p>
                            <p className="item-price">{item.priceRange}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="ads-sidebar">
                <h4>Ads</h4>
                {/* You can add more ad content or components here */}
            </div>
        </div>
    );
};

export default List;
