import axiosInstance from "../helpers/axios";
import { postConstants } from "./constant";


export const postBlog = (data) => {
    console.log(data);
    return async (dispatch) => {

        dispatch({ type: postConstants.POST_BLOG_REQUEST });

        await axiosInstance.post('/api/blog/createPost', data).then(res => {
            if(res.status === 200){
                dispatch({
                    type: postConstants.POST_BLOG_SUCCESS,
                    payload: { posts: res.data.message }
                });
            }else{
                dispatch({
                    type: postConstants.POST_BLOG_FAILURE,
                    payload: { error: res.data.error}
                });
            }
        }).catch(error => {
            console.log(error);
            dispatch({
                type: postConstants.POST_BLOG_FAILURE,
                payload: { error: error}
            });
        });
    }
}

export const getPosts = (page) => {
    console.log(page);
    return async (dispatch) => {
        dispatch({ type: postConstants.GET_POST_REQUEST });

        await axiosInstance.get(`/api/blog/getPosts?page=${page}`).then(res => {
            console.log(res.data.message);
            if(res.status === 200){
                dispatch({
                    type: postConstants.GET_POST_SUCCESS,
                    payload: { posts: res.data }
                });
            }else{
                dispatch({
                    type: postConstants.GET_POST_FAILURE,
                    payload: { error: res.data.error}
                });
            }
        }).catch(error => {
            console.log(error);
            dispatch({
                type: postConstants.GET_POST_FAILURE,
                payload: { error: error}
            });
        });
        
    }
}

export const getTopPosts = () => {
    return async (dispatch) => {
        dispatch({ type: postConstants.GET_POST_BY_SLUG_REQUEST});

        await axiosInstance.get(`/post/top-posts`).then(res => {
            console.log(res.data);
            if(res.status === 200){
                dispatch({
                    type: postConstants.GET_POST_BY_SLUG_SUCCESS,
                    payload: { post: res.data.message }
                });
            }else{
                dispatch({
                    type: postConstants.GET_POST_BY_SLUG_FAILURE,
                    payload: { error: res.data.error}
                });
            }
        }).catch(error => {
            console.log(error);
            dispatch({
                type: postConstants.GET_POST_BY_SLUG_FAILURE,
                payload: { error: error}
            });
        });
    }
};

export const getPostBySlug = (slug) => {
    return async (dispatch) => {
        dispatch({ type: postConstants.GET_POST_BY_SLUG_REQUEST});

        await axiosInstance.get(`/api/blog/getPost/${slug}`).then(res => {
            console.log(res.data);
            if(res.status === 200){
                dispatch({
                    type: postConstants.GET_POST_BY_SLUG_SUCCESS,
                    payload: { post: res.data.message }
                });
            }else{
                dispatch({
                    type: postConstants.GET_POST_BY_SLUG_FAILURE,
                    payload: { error: res.data.error}
                });
            }
        }).catch(error => {
            console.log(error);
            dispatch({
                type: postConstants.GET_POST_BY_SLUG_FAILURE,
                payload: { error: error}
            });
        });
    }
}