import React from 'react'

/**
* @author
* @function TermConditionPage
**/

export const TermConditionPage = (props) => {
    return (
        <div>
            <main>
                <p><strong>Terms and Conditions for Theevmotors.in</strong></p>
                <p><strong>1. Acceptance of Terms</strong></p>
                <p>By accessing and using Theevmotors.in (&quot;the Website&quot;), you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please refrain from using the Website.</p>
                <p><strong>2. User Conduct</strong></p>
                <p>Users are expected to conduct themselves responsibly and ethically while using Theevmotors.in. Any misuse, fraudulent activity, or violation of these terms may result in the termination of the user&apos;s account.</p>
                <p><strong>3. Reviews and Content</strong></p>
                <p>a. Users are responsible for the accuracy and truthfulness of the reviews they submit.</p>
                <p>b. Theevmotors.in reserves the right to moderate, edit, or remove any content deemed inappropriate, offensive, or violating these terms.</p>
                <p>c. Users retain ownership of their reviews but grant Theevmotors.in a worldwide, royalty-free license to use, display, and distribute the content on the Website.</p>
                <p><strong>4. Privacy</strong></p>
                <p>a. Theevmotors.in is committed to protecting user privacy. Please refer to our Privacy Policy for details on the collection and use of personal information.</p>
                <p><strong>5. Intellectual Property</strong></p>
                <p>a. The content on the Website, including logos, trademarks, and other intellectual property, is owned by Theevmotors.in and may not be used without permission.</p>
                <p><strong>6. Termination of Services</strong></p>
                <p>Theevmotors.in reserves the right to terminate or suspend services at any time, with or without notice, for any reason, including but not limited to a violation of these terms.</p>
                <p><strong>7. Disclaimer of Warranties</strong></p>
                <p>a. The Website is provided &quot;as is&quot; without any warranties, express or implied. Theevmotors.in does not guarantee the accuracy, completeness, or reliability of content.</p>
                <p><strong>8. Limitation of Liability</strong></p>
                <p>a. Theevmotors.in shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of the Website.</p>
                <p><strong>9. Governing Law</strong></p>
                <p>a. These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction].</p>
                <p><strong>10. Changes to Terms</strong></p>
                <p>a. Theevmotors.in reserves the right to update or modify these terms at any time. Users are encouraged to check this page regularly for changes.</p>
                <p><br /></p>
                <p>For further inquiries, please contact us at <a data-fr-linked="true" href="mailto:Info@evmotors.in">Info@evmotors.in</a> or visit our address at&nbsp;</p>
                <p>L376, Obeya verbe</p>
                <p>near &nbsp;Sufyan dates and nuts,&nbsp;</p>
                <p>HSR Layout, Bangalore, 560102.</p>
                <p>By using Theevmotors.in, you agree to abide by these terms and conditions.</p>
            </main>

        </div>
    )

}