import React, {useState} from 'react'
import Accordion from 'react-bootstrap/Accordion';

/**
* @author
* @function HydrogenFAQ
**/

export default function HydrogenFAQ({id,title,content}) {
    return (
        <Accordion>
            <Accordion.Item eventKey={id}>
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body>
                {content}
                </Accordion.Body>
              </Accordion.Item>
        </Accordion>
    )

}
