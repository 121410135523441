import React, { useState, useEffect } from 'react';
import { fetchEVHome } from '../../helpers/axios';
import './customPopup.css';
import cancelIcon from '../../images/logo/cancel.png'; // Adjust the path as needed

/**
* @author
* @function CustomPopup
**/

export const CustomPopup = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isDismissed = sessionStorage.getItem('popupDismissed');
    if (!isDismissed) {
      setIsVisible(true);
    }
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    sessionStorage.setItem('popupDismissed', 'true');
  };

  if (!isVisible) {
    return null;
  }

  return(
    <div className='popupfloating'>
        {/* Go green! Install solar panels for a zero-bill EV car.<br/> */}
        {/* <a href="https://api.whatsapp.com/send?phone=9591257807&amp;text=Want%20to%20know%20more?"> Contact Us</a> */}
        {/* <a href='whatsapp://send?text=Hello,%20I%20Want%20to%20know%20more%20about%20zero-bill%20EV%20car?&amp;phone=+918795997070'>Know more</a> */}
        {fetchEVHome}
      <button className="popup-dismiss" onClick={handleDismiss}>
      <img src= {cancelIcon} alt="Dismiss" />
      </button>
      <div className="popup-content">
        Go green! Install solar panels for a zero-bill EV car.
        <br />
        <a href="whatsapp://send?text=Hello,%20I%20Want%20to%20know%20more%20about%20zero-bill%20EV%20car?&amp;phone=+918795997070">Know more</a>
      </div>

    </div>
//     <StyledCustomPopUp id="grand-parent">
//      <StyledLabel>Click the button to open the Custom PopUp</StyledLabel>
//      <StyledButton>
//        <button className="btn" onClick={(e) => handleBtnClick(e)}>
//          Click
//        </button>
//      </StyledButton>
//      <StyledPopUpBackdrop id="backdrop-parent" className={btnState ? "show-modal" : ""}>
//        <StyledPopUp id="popup">
//          <StyledCloseIcon onClick={(e) => handleBtnClick(e)}></StyledCloseIcon>
//          I am a Modal !!
//        </StyledPopUp>
//      </StyledPopUpBackdrop>
//    </StyledCustomPopUp>
   )

 }